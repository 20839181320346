<template>
    <b-modal :id="id" ref="modal" title="Message Thread" header-class="mb-3" footer-class="border-0" ok-only size="lg"
             scrollable>
            <div id="messageBox" class="message-chat-history w-100 p-3" v-lazy-container="{ selector: 'img' }">
                <div v-for="(message, index) in messages" :key="index">
                  <chat-item :message="message" :current-user="null" />
                </div>
            </div>
    </b-modal>
</template>

<script>
import ApiService from '../../../apiService.js'
import ChatItem from '../../Message/ChatItem'
export default {
  name: 'AdminViewMessageThreadModal',
  components: { ChatItem },
  props: {
    'id': {
      type: String,
      required: false,
      default: 'admin-message-thread'
    },
    messages: Array,
    reportedUsername: String
  },
  methods: {
    lookup (hash) {
      ApiService.getMessageAttachment(hash).then(response => {
        this.forceDownload(response.data.data)
      })
    },
    date (date) {
      if (date === null) {
        return this.$t('no_payment_date_message')
      }
      const newDate = new Date(date)
      return newDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }) + ' ' + newDate.toLocaleDateString()
    }
  }
}
</script>

<style scoped>
    .prewrap {
      white-space: pre-wrap;
    }
    .chat-item.object-me .msg {
      background-color: #44aae1 !important;
    }
    a {
        text-decoration: unset !important;
        font-weight: bold;
        color: #fff;
        cursor: pointer;
    }
    .message-chat-history {
        max-height: 40vh;
        min-height: 40vh;
    }

    @media (max-width: 766px) {
        button {
            float: right;
            margin-right: 10px !important;
            margin-top: 0 !important;
        }
    }

    .textWrap {
      overflow-wrap: break-word;
      word-wrap: break-word;
      hyphens: auto;
    }
    .chat-item .chat-item-content {
      max-width: none;
    }
    .text-black {
      color: #000000 !important;
    }
</style>
