<template>
<div class="container">
    <Breadcrumb class="bg-transparent" :breadcrumbList="[
      { 'name': 'Admin Dashboard', 'link': $router.resolve({ name: 'admin' }).href },
      { 'name': 'Reports', 'link': $router.resolve({ name: 'admin_reports' }).href },
      { 'name': action.toUpperCase() }
    ]"/>
    <div class="main-container">
        <h1><strong class="report-title">{{action}} Reports</strong></h1>

        <div class="container">
            <div class="col-xl-12 content-box">
                <div class="row row-featured row-featured-category">
                    <div class="col-xl-12 box-title border-bottom">
                        <div class="inner"><h2 class="report-title">Search {{action}} Reports</h2>
                        </div>
                    </div>
                    <div class="container row mb-3 mt-3">
                            <select name="searchLocked" @change="getReports(1)" v-model="type" class="form-control w-auto mr-3 ml-3">
                                <option value="">All</option>
                                <option value="user">Reported Users</option>
                                <option value="task">Reported Tasks</option>
                                <option value="message">Reported Message Thread</option>
                            </select>
                            <button class="btn btn-primary mr-2" @click="getReports(1)" type="button">
                              <i class="fa-solid fa-magnifying-glass"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="LoadPage">
            <div class="container" v-if="showNoResults">
                <b-alert v-model="showNoResults" variant="success" dismissible>
                    No reports found, please try again
                </b-alert>
            </div>
            <div class="container">
                <div class="col-xl-12 content-box" v-if="reportsArray.length > 0">
                    <div class="row row-featured row-featured-category">
                        <div class="col-xl-12 box-title border-bottom ">
                            <p class="mt-3">{{ $t('showing', {'from': ((currentPage - 1) * reportsPerPage) + 1, 'to': (currentPage * reportsPerPage) > totalReports ? totalReports : currentPage * reportsPerPage, 'total': totalReports }) }}</p>
                        </div>
                    </div>
                    <div class="container mb-3 mt-3">
                        <table class="table table-striped table-bordered add-manage-table table demo footable-loaded footable"
                               data-filter="#filter" data-filter-text-only="true">
                            <thead>
                            <tr>
                                <th>Report Type</th>
                                <th>Report Description</th>
                                <th>Person Reporting</th>
                                <th>Person Reported</th>
                                <th v-if="action === 'resolved'">Checked by</th>
                                <th>View</th>
                                <th>Solution</th>
                            </tr>
                            </thead>
                            <tbody class="mb-md-0 mb-3 text-left">
                            <tr v-for="(report, index) in reportsArray" :key="index" class="cursor">
                                <td>{{reportTypes[report.report.report_type]}} </td>
                                <td>
                                  {{$t(reportTypes[report.report.report_type].toLowerCase() + '_report_reasons')[0][report.report.report_reason]}}
                                </td>
                                <td>
                                    {{report.report.reported_by.username}}
                                </td>
                                <td>{{report.reported}}</td>

                                <td v-if="action === 'resolved'">
                                  {{report.report.checked_by.username}}
                                </td>
                                <td>
                                    <a v-if="report.report.report_type === 1 || report.report.report_type === 2" :href="report.report.resource_url"  target="_blank" class="w-100 text-primary">View</a>
                                    <a v-else v-on:click="messageLookup(report.report.resource_hash, 'admin-message-thread', report.reported)" class="text-primary">View</a>
                                </td>
                                <td>
                                    <button v-if="!report.report.report_response" class="btn btn-primary mr-2" v-on:click="resolve($event, report.report.hash)" type="button">
                                        Resolve
                                    </button>
                                  <div v-else>
                                   <p>
                                     <strong>Solution used for this report:</strong>
                                     <br>
                                     {{inputOptionsData[report.report.report_response]}}
                                   </p>
                                    <p v-if="report.report.response_detail">
                                      <b> Extra Information </b>
                                      <br>
                                      {{report.report.response_detail}}
                                    </p>

                                  </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <Pagination :perPage="reportsPerPage" :currentPage="currentPage" :total="totalReports" v-on:paginationUpdate="updatePagination"  v-if="totalReports > reportsPerPage" class="mt-3"/>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <b-spinner class="mt-5 mb-2 mb-md-4" large label="Large Spinner"></b-spinner>
            <div class="mb-5">Loading Reports</div>
        </div>
         <admin-view-message-thread-modal :messages="messages" :reportedUsername="reportedUsernameMessages"/>
    </div>
</template>

<script>
import ApiService from '../../../apiService.js'
import Pagination from '../../../components/Global/Pagination'
import AdminViewMessageThreadModal from '../../../components/Admin/Reports/AdminViewMessageThreadModal'
import Breadcrumb from '../../../components/Breadcrumbs/Breadcrumb'

export default {
  name: 'AdminReports',
  components: {
    Breadcrumb,
    Pagination,
    AdminViewMessageThreadModal
  },
  data () {
    return {
      action: this.$route.params.action,
      reportsArray: [],
      messages: [],
      reportedUsernameMessages: '',
      currentPage: 1,
      totalReports: 0,
      reportsPerPage: 25,
      LoadPage: true,
      showNoResults: false,
      type: '',
      reportTypes: {
        '1': 'User',
        '2': 'Task',
        '3': 'Message'
      },
      inputOptionsData: {
        1: 'Removed task',
        2: 'Closed Account',
        3: 'Removed message',
        4: 'Removed profile picture',
        5: 'Other'
      }
    }
  },
  methods: {
    getReports (page = null) {
      if (page !== null) {
        this.currentPage = page
      }
      this.LoadPage = false
      this.showNoResults = false
      ApiService.getReports(this.type, this.reportsPerPage, this.currentPage, this.action).then(response => {
        this.reportsArray = response.data.data.results
        this.currentPage = response.data.data.pagination.page
        this.reportsPerPage = response.data.data.pagination.per_page
        this.totalReports = response.data.data.pagination.total
        if (this.reportsArray.length === 0) {
          this.showNoResults = true
        }
        this.LoadPage = true
      }).catch(() => {
      })
    },
    updatePagination (page) {
      this.currentPage = page
      this.getReports()
    },
    messageLookup (hash, id, reported) {
      ApiService.getMessages(hash).then(response => {
        this.messages = response.data.data
        this.reportedUsernameMessages = reported
        this.$bvModal.show(id)
      }).catch(() => {
      })
    },
    resolve (event, hash) {
      event.stopPropagation()
      event.preventDefault()
      this.$swal({
        title: 'Closing report',
        text: 'What action did you take to resolve the problem?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancel',
        showCloseButton: true,
        showLoaderOnConfirm: true,
        input: 'select',
        inputOptions: this.inputOptionsData,
        inputPlaceholder: 'Select a solution',
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (!value) {
              resolve('Missing option')
            } else {
              resolve()
            }
          })
        },
        preConfirm: (result) => {
          if (result !== '5') {
            let data = {
              'response': result
            }
            return ApiService.submitReportSolution(hash, data).then(() => {
            }).catch((error) => {
              let errorMessage = ''
              if (error.response.data.code === 'HTTP-400') {
                errorMessage = error.response.data.errors.children.response.errors[0]
              } else {
                errorMessage = error.response.data.error_message
              }
              this.$swal.showValidationMessage(
                errorMessage
              )
            })
          }
        }
      }).then(result => {
        if (result.value === '5') {
          this.extraInformationNeeded(hash)
        } else if (!result.dismiss) {
          this.$swal({
            title: 'Success',
            text: 'Report has been closed',
            type: 'success',
            allowOutsideClick: false,
            confirmButtonText: 'Close'
          }).then(() => {
            this.getReports()
          })
        }
      })
    },
    extraInformationNeeded (hash) {
      this.$swal({
        title: 'Closing report',
        text: 'You have chosen other, please provide more detail',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancel',
        showCloseButton: true,
        showLoaderOnConfirm: true,
        input: 'textarea',
        inputPlaceholder: 'More infomation',
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (!value) {
              resolve('Missing information')
            } else {
              resolve()
            }
          })
        },
        preConfirm: (result) => {
          let data = {
            'response': 5,
            'detail': result
          }

          return ApiService.submitReportSolution(hash, data).then(() => {
          }).catch((error) => {
            let errorMessage = ''
            if (error.response.data.code === 'HTTP-400') {
              errorMessage = error.response.data.errors.children.detail.errors[0]
            } else {
              errorMessage = error.response.data.error_message
            }
            this.$swal.showValidationMessage(
              errorMessage
            )
          })
        }
      }).then(result => {
        if (!result.dismiss) {
          this.$swal({
            title: 'Success',
            text: 'Report has been closed',
            type: 'success',
            allowOutsideClick: false,
            confirmButtonText: 'Close'
          }).then(() => {
            this.getReports()
          })
        }
      })
    }
  }
}
</script>

<style scoped>
  .report-title {
    text-transform: capitalize;
  }

  .breadcrumbs a:link,
  .breadcrumbs a:visited,
  .breadcrumbs a:hover,
  .breadcrumbs a:active {
    text-decoration: none;
    color: #888;
  }

  .breadcrumbs:hover,
  .breadcrumbs a {
    cursor: default;
  }

  a.clickable:hover {
    cursor: pointer;
    color: #000 !important;
    text-decoration: underline !important;
  }

  .breadcrumbs {
    z-index: 3;
    color: #888 !important;
  }

  .breadcrumbs i {
    color: #888;
  }
</style>
