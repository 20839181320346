<template>
  <div class="container">
    <Breadcrumb class="bg-transparent" :breadcrumbList="[
      { 'name': 'Admin Dashboard', 'link': $router.resolve({ name: 'admin' }).href },
      { 'name': 'Reports' }
    ]"/>
    <div class="main-container">
      <h1 class=" mb-2"><strong>Reports</strong></h1>
      <div class="row mt-5 justify-content-center" v-if="this.$store.getters.hasRole('ROLE_ADMIN_REPORT')">
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4" >
          <Cards type="svg" icon-type="fa-regular" image="fa-check-circle" title="Resolved Reports" :route="'/admin/reports/resolved'"/>
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4 " >
          <Cards type="svg" image="fa-hourglass-start" title="Unresolved Reports" :route="'/admin/reports/unresolved'"/>
        </div>
      </div>
      <div v-else>
        <b-alert variant="danger" fade show>If you feel like you not seeing the right information, please try and re-login</b-alert>
      </div>
    </div>
  </div>
</template>

<script>
import Cards from '../../../components/Admin/AdminCards'
import { mapActions } from 'vuex'
import Breadcrumb from '../../../components/Breadcrumbs/Breadcrumb'

export default {
  name: 'AdminReportsLandingPage',
  components: {
    Breadcrumb,
    Cards
  },
  mounted () {
    this.buildUser()
  },
  methods: {
    ...mapActions([
      'buildUser'
    ])
  }
}
</script>

<style scoped>

</style>
